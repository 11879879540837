.slider-controls {
  display: flex;

  &__prev,
  &__next {
    cursor: pointer;
    font-weight: 700;
    color: $c-black;
    position: relative;
    user-select: none;
    font-family: $f-orbitron, sans-serif;

    @include any-hover {
      &:hover {
        &::after {
          width: 40px;

          @include media-max($laptop) {
            width: 35px;
          }
        }
      }
    }

    &::after {
      position: absolute;
      bottom: -7px;
      left: 0;
      content: '';
      width: 59px;
      height: 1px;
      background-color: currentColor;
      pointer-events: none;
      transition: width $t-base;

      @include media-max($laptop) {
        width: 56px;
        bottom: -5px;
      }
    }
  }

  &__next {
    margin-left: 48px;
    color: $c-violet;

    margin-left: 54px;

    &::after {
      right: 0;
      left: initial;
    }
  }
}