.tabs {
	&__controls {
		display: flex;
		align-items: center;
		padding-bottom: 20px;
		border-bottom: 1px solid $c-black;

		@include media-max($laptop) {
			padding-bottom: 15px;
		}

		@include media-max($phablet) {
			display: none;
		}

		li {
			&:not(:last-child) {
				margin-right: 45px;

				@include media-max($laptop) {
					margin-right: 32px;
				}
			}

			&.ui-tabs-active {
				.tabs__control {
					color: $c-black;

					&::after {
						transform: translateX(-50%) scale(1);
					}
				}
			}
		}
	}

	&__control {
		display: block;
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		text-transform: capitalize;
		color: rgba($c-black, 0.5);
		transition: color $t-base;
		position: relative;
		font-family: $f-orbitron, sans-serif;

		@include media-max($laptop) {
			font-size: 16px;
			line-height: 24px;
		}

		@include any-hover {
			&:hover {
				color: $c-black;
			}
		}

		&::after {
			position: absolute;
			content: '';
			bottom: -26px;
			left: 50%;
			transform: translateX(-50%) scale(0);
			width: 11px;
			height: 11px;
			border-radius: 50%;
			background-color: $c-violet;
			transition: transform $t-base;

			@include media-max($laptop) {
				bottom: -21px;
			}
		}
	}

	&__contents {
		padding-top: 90px;

		@include media-max($laptop) {
			padding-top: 56px;
		}

		@include media-max($phablet) {
			padding-top: 40px;
		}
	}

	&__content {
	}

	&--two {
		display: grid;
		grid-template-columns: 1fr 308px;
		column-gap: 90px;
		align-items: flex-start;

		@include media-max($laptop) {
			grid-template-columns: 1fr 260px;
			column-gap: 56px;
		}

		@include media-max($phablet) {
			display: block;
		}

		.tabs__controls {
			position: sticky;
			top: 144px;
			background-color: $c-white;
			padding: 40px 32px;
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			border-bottom: none;
			grid-column-start: 2;

			@include media-max($laptop) {
				padding: 32px 24px;
			}

			@include media-max($phablet) {
				display: none;
			}

			li {
				&:not(:last-child) {
					margin-right: 0;
					margin-bottom: 16px;
				}

				&.ui-tabs-active {
					.tabs__control {
						color: $c-violet;
					}
				}
			}
		}

		.tabs__control {
			text-align: left;
			font-weight: 500;
			color: $c-black;
			transition: color $t-base;
			font-size: 16px;
			margin-right: 0;
			line-height: 28px;
			font-family: $f-montserrat, sans-serif;

			@include media-max($laptop) {
				font-size: 14px;
				line-height: 24px;
			}

			&::after {
				display: none;
			}

			@include any-hover {
				&:hover {
					color: $c-violet;
				}
			}
		}

		.tabs__contents {
			padding-top: 0;
			grid-column-start: 1;
			grid-row-start: 1;
		}

		.tabs__content {
			@include media-max($phablet) {
				display: none !important;
			}

			& > img {
				margin-bottom: 56px;

				@include media-max($laptop) {
					margin-bottom: 32px;
				}

				@include media-max($phablet) {
					margin-bottom: 24px;
				}
			}

			&.active {
				display: block;

				h2 {
					@include media-max($phablet) {
						display: none;
					}
				}

				@include media-max($phablet) {
					padding-bottom: 16px;
					display: block !important;
				}
			}
		}
	}
}
