.card-industries {
  height: 383px;
  position: relative;
  user-select: none;
  border-radius: 10px;
  overflow: hidden;

  .read-more {
    pointer-events: none;

    svg {
      fill: $c-white;
    }
  }

  @include media-max($laptop) {
    height: 280px;
  }

  @include media-max($phablet) {
    height: 228px;
  }

  @include any-hover {
    &:hover {
      &::after {
        opacity: 1;
      }

      .read-more {
        color: $c-white;

        span {
          padding-left: 0;
          padding-right: 25px;
        }

        svg {
          right: 0;
          fill: $c-white;
          transform: translate(0, calc(-50% - 1px));
        }
      }
    }
  }

  &::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background: linear-gradient(35deg, rgba(86, 34, 191, 0.50) 3.35%, rgba(0, 249, 234, 0.50) 94.37%);;
    opacity: 0;
    transition: opacity $t-base;
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    color: $c-white;

    @include media-max($laptop) {
      padding: 24px 16px;
    }
  }
}