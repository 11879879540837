.about-contacts {
  display: flex;
  align-items: center;

  @include media-max($phablet) {
    flex-direction: column;
  }

  &:not(:last-child) {
    @include media-max($phablet) {
      margin-bottom: 40px;
    }
  }

  &__prew {
    height: 383px;
    width: 50%;
    border-radius: 10px;
    overflow: hidden;

    @include media-max($laptop) {
      height: 281px;
    }

    @include media-max($phablet) {
      width: 100%;
      height: 228px;
      margin-bottom: 32px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    width: 50%;
    padding-left: 72px;

    @include media-max($laptop) {
      padding-left: 48px;
    }

    @include media-max($phablet) {
      width: 100%;
      padding: 0;
    }
  }

  &__title {
    margin-bottom: 40px;

    @include media-max($laptop) {
      margin-bottom: 24px;
    }

    @include media-max($phablet) {
      margin-bottom: 16px;
    }
  }

  &__list {
    margin-bottom: 40px;

    @include media-max($laptop) {
      margin-bottom: 24px;
    }

    @include media-max($phablet) {
      margin-bottom: 16px;
    }
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 12px;

      @include media-max($laptop) {
        margin-bottom: 8px;
      }
    }

    img {
      width: 24px;
      height: 24px;
      margin-right: 18px;

      @include media-max($laptop) {
        margin-right: 16px;
      }
    }

    a {
      transition: color $t-base;

      @include any-hover {
        &:hover {
          color: $c-accent;
        }
      }
    }
  }

  &__link {
    color: $c-violet;

    svg {
      fill: $c-violet;
    }
  }

  &--rtl {
    flex-direction: row-reverse;

    @include media-max($phablet) {
      flex-direction: column;
    }

    .about-contacts__content {
      padding-left: 0;
      padding-right: 72px;

      @include media-max($laptop) {
        padding-right: 48px;
      }

      @include media-max($phablet) {
        padding: 0;
      }
    }
  }
}