.article {
	&:not(:last-child) {
		margin-bottom: 56px;

		@include media-max($laptop) {
			margin-bottom: 32px;
		}

		@include media-max($phablet) {
			margin-bottom: 24px;
		}
	}

	& > *:first-child {
		margin-top: 0;
	}

	& > *:last-child {
		margin-bottom: 0;
	}

	h2 {
		font-weight: 700;
		font-size: 32px;
		line-height: 40px;
		text-transform: capitalize;
		margin-bottom: 32px;

		@include media-max($laptop) {
			font-size: 20px;
			line-height: 28px;
			margin-bottom: 20px;
		}
	}

	h3 {
		font-weight: 700;
		font-size: 24px;
		line-height: 32px;
		text-transform: capitalize;
		margin-bottom: 24px;

		@include media-max($laptop) {
			font-size: 16px;
			line-height: 24px;
			margin-bottom: 16px;
		}
	}

	p {
		margin: 16px 0;
	}

	ul,
	ol {
		margin: 20px 0;

		&:not(:last-child) {
			margin-bottom: 32px;

			@include media-max($laptop) {
				margin-bottom: 24px;
			}

			@include media-max($phablet) {
				margin-bottom: 16px;
			}
		}

		li {
			& > ul,
			& > ol {
				margin-top: 8px;
				margin-bottom: 16px;
			}
		}
	}

	ol {
		list-style-type: none;
		counter-reset: olcounter;

		& > li {
			counter-increment: olcounter;
			position: relative;
			padding-left: 20px;

			& > ul,
			& > ol {
				padding-left: 20px;
			}

			&::before {
				content: counter(olcounter) '. ';
				position: absolute;
				left: 0;
			}
		}
	}

	ul {
		& > li {
			padding-left: 24px;
			position: relative;

			&::before {
				position: absolute;
				left: 0;
				top: 4px;
				width: 16px;
				height: 16px;
				border-radius: 50%;
				border: 1px solid $c-violet;
				content: '';
			}
		}
	}

	figure {
		ul {
			& > li {
				padding-left: 0;

				&::before {
					display: none;
				}
			}
		}
	}

	a {
		color: $c-violet;
		text-decoration: none;

		@include any-hover {
			&:hover {
				text-decoration: underline;
			}
		}
	}
}
