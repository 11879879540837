.section-text {
  padding-bottom: 140px;

  @include media-max($laptop) {
    padding-bottom: 90px;
  }

  @include media-max($tablet) {
    padding-bottom: 64px;
  }

  @include media-max($phablet) {
    padding-bottom: 56px;
  }
  .scheme {
      max-width: 70%;
  }

}