.read-more {
  display: inline-flex;
  align-items: center;
  position: relative;
  transition: color $t-base;

  @include any-hover {
    &:hover {
      color: $c-violet;

      span {
        padding-left: 0;
        padding-right: 25px;
      }

      svg {
        right: 0;
        fill: $c-violet;
        transform: translate(0, calc(-50% - 1px));
      }
    }
  }

  span {
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.05em;
    padding-left: 25px;
    transition: padding $t-base;
    font-family: $f-orbitron, sans-serif;

    @include media-max($laptop) {
      font-size: 14px;
      line-height: 24px;
    }
  }

  svg {
    width: 17px;
    height: 10px;
    fill: $c-black;
    position: absolute;
    top: 50%;
    right: 100%;
    transform: translate(17px, calc(-50% - 1px));
    transition: all $t-base;
    pointer-events: none;
    margin-top: 2px;
  }
}