.section-solutions {
    position: relative;

    &--line {
        &::after {
          position: absolute;
          top: 0;
          left: 0;
          content: '';
          width: 100%;
          height: 1px;
          background-color: $c-white;
          left: calc((100vw - $width-container) / 2);
    
          @include media-max($laptop) {
            left: 32px;
          }
    
          @include media-max($tablet) {
            left: 16px;
          }
    
          @include media-max($phablet) {
            left: 0;
            opacity: 0.2;
          }
        }
      }
}