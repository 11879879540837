.header-nav {
  @include media-max($tablet) {
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    width: 100%;
    height: calc(100vh - 56px);
    background-color: $c-white;
    padding: 24px 16px;
    opacity: 0;
    pointer-events: none;

    &.active {
      opacity: 1;
      pointer-events: initial;
    }
  }

  &__list {
    display: flex;
    align-items: center;

    @include media-max($tablet) {
      flex-direction: column;
      align-items: flex-start;
    }

    & > li {
      &:not(:last-child) {
        margin-right: 56px;
  
        @include media-max($laptop) {
          margin-right: 40px;
  
          @include media-max($tablet) {
            margin-right: 0;
            margin-bottom: 14px;
          }
        }
      }

      &.current-menu-item {
        & > a {
          @include media-max($tablet) {
            color: $c-accent;
          }
    
          &::before {
            transform: scale(1);
    
            @include media-max($tablet) {
              transform: scale(0);
            }
          }
        }
      }

      & > a {
        position: relative;
        transition: color $t-base;

        @include any-hover {
          &:hover {
            @include media-max($tablet) {
              color: $c-accent;
            }

            &::before {
              transform: scale(0.8);

              @include media-max($tablet) {
                transform: scale(0);
              }
            }
          }
        }

        &::before {
          width: 14px;
          height: 14px;
          border-radius: 3px;
          background: linear-gradient(145deg, #5622BF 7.4%, #00F9F9 82.75%);
          content: '';
          position: absolute;
          left: -19px;
          top: 2px;
          transform: scale(0);
          transition: transform $t-base;
        }
      }
    }
  }

  &__item {
    &:not(:last-child) {
      margin-right: 56px;

      @include media-max($laptop) {
        margin-right: 40px;

        @include media-max($tablet) {
          margin-right: 0;
          margin-bottom: 14px;
        }
      }
    }

    &.current-menu-item {
      .header-nav__link {
        @include media-max($tablet) {
          color: $c-violet;
        }
  
        &::before {
          transform: scale(1);
  
          @include media-max($tablet) {
            transform: scale(0);
          }
        }
      }
    }
  }

  &__link {
    position: relative;
    transition: color $t-base;

    @include any-hover {
      &:hover {
        @include media-max($tablet) {
          color: $c-accent;
        }

        &::before {
          transform: scale(0.8);

          @include media-max($tablet) {
            transform: scale(0);
          }
        }
      }
    }

    &::before {
      width: 14px;
      height: 14px;
      border-radius: 3px;
      background-color: linear-gradient(145deg, #5622BF 7.4%, #00F9F9 82.75%);
      content: '';
      position: absolute;
      left: -19px;
      top: 2px;
      transform: scale(0);
      transition: transform $t-base;
    }
  }
}