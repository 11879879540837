.section-documents {
	position: relative;
	overflow: hidden;

	&__header {
		margin-bottom: 40px;
	}

	&__title {
		margin-bottom: 24px;

		@include media-max($laptop) {
			margin-bottom: 16px;
		}
	}

	&__grid {
		display: grid;
		grid-template-columns: repeat(auto-fill, minmax(304px, 1fr));
		gap: 32px;

		@include media-max($laptop) {
			gap: 24px;
		}
	}
}
