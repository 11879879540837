.card-leadership {
	border-radius: 10px;
	background: linear-gradient(86deg, #00f9f9 0.29%, #5622bf 75.41%);
	padding: 24px;
	display: flex;
	flex-direction: column;
	row-gap: 8px;

	@include media-max($tablet) {
		padding: 16px;
	}

	&__inner {
		background-color: $c-white;
		display: flex;
		border-radius: 10px;
		overflow: hidden;

		@include media-max($phablet) {
			flex-direction: column;
		}
	}

	&__preview {
		width: 50%;
		display: flex;
		aspect-ratio: 1 / 1;
		cursor: pointer;
		border: none;
		background-color: transparent;
		flex-shrink: 0;
		position: relative;

		@include media-max($phablet) {
			width: 100%;
		}

		&:hover {
			&::after {
				opacity: 1;
			}
		}

		&::after {
			content: '';
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			background-image: url('./../img/cl-cover.png');
			background-size: cover;
			background-position: center;
			opacity: 0;
			transition: opacity $t-base;

			@include media-max($phablet) {
				display: none;
			}
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__content {
		width: 50%;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		justify-content: flex-end;
		row-gap: 8px;
		padding: 24px 16px;

		@include media-max($mobile) {
			width: 100%;
			min-height: 180px;
		}
	}

	&__name {
		font-size: 20px;
		font-weight: 700;
		line-height: 1.2;

		@include media-max($laptop) {
			font-size: 16px;
		}
	}

	&__post {
		font-size: 16px;
		line-height: 1.4;

		@include media-max($laptop) {
			font-size: 14px;
		}
	}

	&__link {
		color: $c-violet;

		svg {
			fill: $c-violet;
		}
	}

	&__dropdown {
		background-color: $c-white;
		padding: 24px 32px;
		border-radius: 10px;
		display: none;

		& > p + p {
			margin-top: 20px;
		}
	}

	&.is-active {
		.card-leadership__dropdown {
			display: block;
		}
	}
}
