.partners-benefits {
  &__title {
    margin-bottom: 64px;

    @include media-max($laptop) {
      margin-bottom: 48px;
    }

    @include media-max($phablet) {
      margin-bottom: 32px;
    }

    &::after {
      content: '?';
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;

    @include media-max($laptop) {
      gap: 24px;
    }

    @include media-max($phablet) {
      grid-template-columns: 100%;
      gap: 16px;
    }
  }

  &__item {
    background-color: $c-white-dark;
    padding: 40px 32px;
    border-radius: 10px;
    overflow: hidden;

    @include media-max($laptop) {
      padding: 32px 24px;
    }
  }

  &__item-icon {
    width: 80px;
    height: 80px;
    margin-bottom: 24px;

    @include media-max($laptop) {
      width: 56px;
      height: 56px;
      margin-bottom: 16px;
    }
  }

  &__item-title {
    font-weight: 700;
    margin-bottom: 8px;
    font-family: $f-orbitron, sans-serif;
  }

  &__item-text {}
}