.subscribe-form {
  background-image: url('./../img/itdr/email-subs.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  position: relative;

  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @include media-max($phablet) {
    background-position: right -200px bottom;
  }

  &__title {
    margin-bottom: 20px;
    color: $c-white;
    position: relative;
    z-index: 1;

    @include media-max($phablet) {
      margin-bottom: 32px;
    }

    &::after {
      content: '!';
      color: $c-accent;
    }
  }

  iframe {
    position: relative;
    z-index: 1;
  }

  p {
    color:white;
    margin-bottom: 40px;
  }
}