.main-score {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: auto;

  @include media-max($phablet) {
    left: 0;
    width: 100%;
    height: 300px;
    position: absolute;
    bottom: 0;
    background-color: $c-white;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, auto));
    justify-content: space-evenly;
    align-content: center;
    column-gap: 46px;
    row-gap: 20px;
    padding: 0 16px;
  }

  &__item {
    display: flex;
    align-items: center;

    @include media-max($phablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__nubmer {
    font-weight: 700;
    font-size: 56px;
    line-height: 64px;
    margin-right: 24px;
    font-family: $f-orbitron, sans-serif;
    color: $c-accent;

    @include media-max($laptop) {
      font-size: 32px;
      line-height: 40px;
      margin-right: 16px;
    }

    @include media-max($phablet) {
      line-height: 32px;
      color: $c-accent;
      margin-bottom: 8px;
    }
  }
}