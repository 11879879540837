.main-products {
  &__title {
    margin-bottom: 65px;

    @include media-max($laptop) {
      margin-bottom: 40px;
    }

    @include media-max($phablet) {
      display: none;
    }
  }

  &__content {
    max-width: 640px;
  }

  .tabs__controls {
    @include media-max($phablet) {
      display: none;
    }
  }

  .tabs__contents {
    padding-top: 45px;

    @include media-max($laptop) {
      padding-top: 36px;
    }

    @include media-max($phablet) {
      padding-top: 0;
    }
  }

  .tabs__content {
    h2 {
      display: none;

      @include media-max($phablet) {
        display: inline-block;
      }
    }

    @include media-max($phablet) {
      display: block !important;

      &:not(:last-child) {
        margin-bottom: 56px;
      }
    }
  }

  .read-more {
    margin-top: 24px;

    @include media-max($laptop) {
      margin-top: 16px;
    }
  }

  .slider-base {
    margin-top: 56px;

    @include media-max($laptop) {
      margin-top: 40px;
    }
  }
}