.card-document {
	background-color: $c-violet;
	border-radius: 10px;
	padding: 40px 32px;
	display: flex;
	flex-direction: column;
	row-gap: 16px;

	@include media-max($laptop) {
		padding: 32px 24px;
	}

	@include media-max($phablet) {
		padding: 24px 16px;
	}

	&__name {
		color: $c-white;
		font-family: $f-orbitron;
		font-size: 24px;
		font-weight: 700;
		text-transform: capitalize;

		@include media-max($laptop) {
			font-size: 16px;
		}
	}

	&__preview {
		flex: 1 1 auto;
		position: relative;
		border-radius: 10px;
		overflow: hidden;

		&:hover {
			.card-document__cover {
				opacity: 1;
			}

			.card-document__link {
				transform: scale(1);
			}
		}

		& > img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
		}
	}

	&__cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-image: url('./../img/doc-cover.png');
		background-size: cover;
		background-position: center;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: flex-end;
		padding: 32px;
		opacity: 0;
		transition: opacity $t-base;

		@include media-max($phablet) {
			opacity: 1;
		}
	}

	&__link {
		transform: scale(1.1);
		transition: background-color $t-base, transform $t-base;
	}
}
