.slider-logos {
  height: 88px;
  margin-right: 0;
  overflow: visible;
  pointer-events: none;

  @include media-max($tablet) {
    width: 100%;
  }

  .swiper-slide {
    opacity: 0;
    transition: opacity $t-base;
  }

  .swiper-slide-active {
    opacity: 1;
  }
}