.section-first {
  width: 100%;
  min-height: 100vh;
  display: flex;
  align-items: center;
  background-color: $c-white;

  @include media-max($laptop) {
    min-height: 600px;
  }

  @include media-max($phablet) {
    min-height: 400px;
  }

  @include media-max($phablet) {
    min-height: 0;
    display: block;
    padding-top: 70px;
    padding-bottom: 56px;
  }

  &__content {
    flex: 1 1 auto;
    padding-right: 90px;
    padding-left: calc((100vw - $width-container) / 2);
    padding-top: 122px;

    @include media-max($laptop) {
      padding-right: 48px;
      padding-left: 32px;
      padding-top: 68px;
    }

    @include media-max($tablet) {
      padding-right: 32px;
      padding-left: 16px;
    }

    @include media-max($phablet) {
      padding-right: 16px;
      margin-bottom: 40px;
      padding-top: 0;
    }
    ul {
		margin: 20px 0;

		&:not(:last-child) {
			margin-bottom: 32px;

			@include media-max($laptop) {
				margin-bottom: 24px;
			}

			@include media-max($phablet) {
				margin-bottom: 16px;
			}
		}

		li {
			& > ul,
			& > ol {
				margin-top: 8px;
				margin-bottom: 16px;
			}
		}
	}

    ul {
      & > li {
        padding-left: 24px;
        position: relative;
  
        &::before {
          position: absolute;
          left: 0;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid $c-violet;
          content: '';
        }
      }
    }
  }

  &__title {
    margin-bottom: 40px;

    @include media-max($laptop) {
      margin-bottom: 24px;
    }

    @include media-max($phablet) {
      margin-bottom: 16px;
    }
  }

  &__desc {
    max-width: 574px;
    margin-bottom: 48px;

    @include media-max($laptop) {
      margin-bottom: 32px;
    }

    @include media-max($phablet) {
      margin-bottom: 16px;
    }
  }

  &__prew {
    width: 900px;
    height: 100vh;
    max-width: 50%;
    padding-top: 112px;
    flex-shrink: 0;

    @include media-max($laptop) {
      height: 600px;
      padding-top: 68px;
    }

    @include media-max($phablet) {
      width: 100%;
      max-width: 440px;
      height: 228px;
      padding: 0 16px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center top;
      border-radius: 40px 0 0 0;

      @include media-max($phablet) {
        border-radius: 10px;
      }
    }
  }
}