.section-news {
  padding: 140px 0 !important;

	@include media-max($laptop) {
		padding: 90px 0 !important;
	}

	@include media-max($tablet) {
		padding: 64px 0 !important;
	}

	@include media-max($phablet) {
		padding: 56px 0 !important;
	}

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__header {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 65px;

    h2 {
      margin-bottom: 0;

      @include media-max($phablet) {
        margin-bottom: 32px;
      }
    }

    @include media-max($laptop) {
      margin-bottom: 48px;
    }

    @include media-max($phablet) {
      display: block;
      margin-bottom: 32px;
    }
  }

  &__item {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__item-prew {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 40px;
    overflow: hidden;
    border-radius: 10px;

    @include media-max($laptop) {
      margin-bottom: 24px;
    }

    @include any-hover {
      &:hover {
        &::before {
          background-color: rgba($c-accent, 0.15);
        }

        img {
          transform: scale(1.05);
        }
      }
    }

    &::before {
      position: absolute;
      content: '';
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background-color: rgba(17, 19, 25, 0.15);
      background-blend-mode: hard-light;
      z-index: 1;
      transition: background-color $t-base;
    }

    &::after {
      content: '';
      display: block;
      padding-top: 100%;

      @include media-max($phablet) {
        padding-top: 79%;
      }
    }

    img {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
      transition: transform $t-base;
    }
  }

  &__item-date {
    position: absolute;
    bottom: 2px;
    left: 2px;
    padding: 10px 16px;
    background: rgba($c-black, 0.75);
    font-weight: 500;
    z-index: 1;
    border-radius: 10px;
    color: $c-white;

    @include media-max($laptop) {
      padding: 6px 16px;
      font-size: 12px;
      line-height: 20px;
    }
  }

  &__item-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    text-transform: capitalize;
    margin-bottom: 24px;
    transition: color $t-base;
    font-family: $f-orbitron, sans-serif;

    @include media-max($laptop) {
      font-size: 16px;
      line-height: 24px;
      margin-bottom: 16px;
    }

    @include any-hover {
      &:hover {
        color: $c-violet;
      }
    }
  }


  &__btn {
    margin-top: 64px;

    @include media-max($laptop) {
      margin-top: 48px;
    }

    @include media-max($phablet) {
      margin-top: 32px;
    }
  }
}