.customer-cases {
  padding-bottom: 0;

  &__title {
    margin-bottom: 56px;

    @include media-max($laptop) {
      margin-bottom: 48px;
    }

    @include media-max($phablet) {
      margin-bottom: 24px;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 32px;

    @include media-max($laptop) {
      gap: 24px;
    }

    @include media-max($phablet) {
      grid-template-columns: 100%;
      gap: 16px;
    }
  }
}