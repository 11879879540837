.section-base {
  margin-bottom: 90px;
  display: grid;
  grid-template-columns: minmax(auto, 642px) 1fr;
  column-gap: 100px;
  align-items: center;

  @include media-max($laptop) {
    grid-template-columns: minmax(auto, 400px) 1fr;
    column-gap: 60px;
    margin-bottom: 56px;
  }

  @include media-max($tablet) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  @include media-max($phablet) {
    margin-bottom: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  &__prew {
    width: 100%;
    height: 510px;
    border-radius: 10px;
    overflow: hidden;

    @include media-max($laptop) {
      height: 318px;
    }

    @include media-max($tablet) {
      max-width: 500px;
      margin-bottom: 32px;
    }

    @include media-max($phablet) {
      height: 228px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__content {
    &>*:last-child {
      margin-bottom: 0;
    }

    ul,
    ol {
      margin: 16px 0;

      li {

        &>ul,
        &>ol {
          margin-top: 8px;
          margin-bottom: 16px;
        }
      }
    }

    ol {
      list-style-type: none;
      counter-reset: olcounter;

      &>li {
        padding-left: 20px;
        counter-increment: olcounter;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          top: 0;
          content: counter(olcounter) ". ";
        }
      }
    }

    ul {
      &>li {
        padding-left: 24px;
        position: relative;

        &::before {
          position: absolute;
          left: 0;
          top: 4px;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid $c-violet;
          content: '';
        }
      }
    }
  }

  &__title {
    margin-bottom: 24px;

    @include media-max($laptop) {
      margin-bottom: 8px;
    }
  }

  &__subtitle {
    margin-bottom: 40px;
    font-weight: 500;
    color: $c-violet;

    @include media-max($laptop) {
      margin-bottom: 24px;
    }

    @include media-max($phablet) {
      margin-bottom: 16px;
    }
  }

  &__text {
    margin-bottom: 48px;

    @include media-max($laptop) {
      margin-bottom: 32px;
    }

    @include media-max($phablet) {
      margin-bottom: 24px;
    }
  }

  &--rtl {
    grid-template-columns: 1fr minmax(auto, 642px);

    @include media-max($laptop) {
      grid-template-columns: 1fr minmax(auto, 400px);
    }

    @include media-max($tablet) {
      flex-direction: column-reverse;
    }
  }
}