.tabs-filter {
  &__controls {
    display: flex;
    flex-wrap: wrap;
    margin-top: -16px;
    margin-bottom: 56px;

    @include media-max($laptop) {
      margin-bottom: 40px;
    }

    @include media-max($phablet) {
      margin-top: -8px;
    }

    li {
      &:not(:last-child) {
        margin-right: 16px;

        @include media-max($phablet) {
          margin-right: 8px;
        }
      }

      &.ui-tabs-active {
        .tabs-filter__control {
          background-color: $c-accent;

          @include any-hover {
            &:hover {
              color: $c-black;
            }
          }
        }
      }
    }
  }

  &__control {
    display: block;
    font-weight: 700;
    font-family: $f-orbitron, sans-serif;
    padding: 6px 16px;
    background-color: $c-white;
    border-radius: 3px;
    margin-top: 16px;
    line-height: 28px;
    transition: color $t-base, background-color $t-base;

    @include media-max($phablet) {
      margin-top: 8px;
    }

    @include media-max($laptop) {
      line-height: 24px;
      padding: 4px 16px;
    }

    @include any-hover {
      &:hover {
        color: $c-violet;
      }
    }
  }
}