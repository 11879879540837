.logo {
	display: flex;

	img {
		height: 52px;
		transition: opacity $t-base;
		transition: all $t-base;

		@include media-max($laptop) {
			height: 32px;
		}

		@include media-max($tablet) {
			height: 24px;
		}
	}

	@include any-hover {
		&:hover {
			img {
				opacity: 0.8;
			}
		}
	}
}
