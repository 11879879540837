.btn-reset {
	background-color: transparent;
	border: none;
	color: $c-black;
	cursor: pointer;
}

.btn {
	display: inline-flex;
	padding: 18px 48px;
	border-radius: 4px;
	background-color: $c-accent;
	font-size: 16px;
	line-height: 28px;
	font-weight: 700;
	transition: background-color $t-base;
	cursor: pointer;
	font-family: $f-orbitron, sans-serif;

	@include media-max($laptop) {
		font-size: 14px;
		line-height: 24px;
		padding: 12px 24px;
	}

	&:hover {
		background-color: darken($c-accent, 5%);
	}
}

.btn-text {
 margin-top: 40px;
}
