.main-first-screen {
  max-width: 1920px;
  margin: 0 auto;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background: url('./../img/main-first-screen.jpg') no-repeat center / cover;
  background-blend-mode: normal, hard-light;
  padding: 64px 0;

  @include media-max($laptop) {
    min-height: 600px;
    padding: 48px 0;
  }

  @include media-max($phablet) {
    min-height: 700px;
    padding-bottom: 300px;
    position: relative;
    background: linear-gradient(86deg, rgba(0, 249, 249, 0.35) 0.29%, rgba(86, 34, 191, 0.35) 75.41%), linear-gradient(0deg, rgba(17, 19, 25, 0.4), rgba(17, 19, 25, 0.4)), url("./../img/main-first-screen-mobile.jpg") no-repeat bottom 300px right -30px/auto calc(100% - 356px);
    background-size: cover;
    background-position: center;
  }

  &__inner {
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;

    h1 {
      max-width: 640px;
      margin-bottom: 48px;
      margin-top: auto;

      @include media-max($laptop) {
        margin-bottom: 32px;
      }

      @include media-max($phablet) {
        margin-bottom: 24px;
        color: $c-white;
      }
    }

    .btn {
      @include media-max($phablet) {
        margin-bottom: auto;
      }
    }
  }
}