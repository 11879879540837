.select {
  display: none;
  margin-bottom: 0;

  .choices__inner {
    background-color: $c-white;
    border: none;
    border-radius: none;
    padding: 8px 16px;
    display: flex;
    align-items: center;
    min-height: 40px;

    .choices__list--single {
      padding: 0;

      .choices__item {
        font-size: 16px;
        line-height: 24px;
        font-weight: 700;
      }
    }
  }

  &[data-type*=select-one]::after {
    width: 12px;
    height: 7.4px;
    background-image: url('./../img/icons/arrow-down.svg');
    background-size: cover;
    border: none;
    right: 16px;
    margin: 0;
    transform: translateY(-50%);
  }

  &[data-type*=select-one].is-open::after {
    margin: 0;
    transform: translateY(-50%) rotate(180deg);
  }

  .choices__list--dropdown {
    background-color: $c-white;
    border: none;
    border-top: 1px solid $c-gray;
    padding: 16px;
    z-index: 2;

    .choices__item {
      padding: 0;
      color: $c-black;
      font-size: 16px;
      line-height: 24px;

      &:not(:last-child) {
        margin-bottom: 4px;
      }
    }
  }

  @include media-max($phablet) {
    display: block;
  }
}

.is-open .choices__list--dropdown,
.is-open .choices__list[aria-expanded] {
  border-color: $c-gray;
}

.choices__list--dropdown .choices__item--selectable.is-highlighted,
.choices__list[aria-expanded] .choices__item--selectable.is-highlighted {
  background-color: transparent;
  color: $c-violet;
}