.tag,
.tag-cloud-link,
a[rel=tag] {
  display: inline-block;
  background-color: $c-white-dark;
  padding: 4px 8px;
  border-radius: 3px;
  transition: background-color $t-base;
  font-size: 14px;
  line-height: 24px;

  @include any-hover {
    &:hover {
      background-color: $c-accent;
    }
  }
}