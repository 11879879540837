.mobile-menu-btn {
  width: 24px;
  height: 24px;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  @include media-max($tablet) {
    display: flex;
  }

  span {
    width: 18px;
    height: 2px;
    background-color: $c-black;
    transition: all $t-base;

    &:nth-child(2) {
      margin: 3px 0;
    }
  }

  &.active {
    span {
      &:first-child {
        transform: translateY(5px) rotate(45deg);
      }

      &:nth-child(2) {
        opacity: 0;
      }

      &:last-child {
        transform: translateY(-5px) rotate(-45deg);
      }
    }
  }
}