.products-content {
  .slider-base {
    margin-bottom: 140px;

    @include media-max($laptop) {
      margin-bottom: 90px;
    }

    @include media-max($tablet) {
      margin-bottom: 64px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &>*:last-child {
    margin-bottom: 0;
  }
}