// Шрифты
$f-montserrat: 'Montserrat';
$f-orbitron: 'Orbitron';

// Цвета
$c-white: #fdfdfd;
$c-white-dark: #efedf5;
$c-black: #141414;
$c-gray: #c4c4c4;
$c-gray-light: #a9afc3;
$c-accent: #00f9f9;
$c-violet: #5622bf;

// Цвета состояния кнопок
$c-button-hover: #dc33b9;
$c-button-active: #7a1565;

// Тень
$s-base: 0px 20px 50px rgba(0, 0, 0, 0.1);

// Переходы
$t-base: 0.2s ease-in-out;

// Медиавыражения
$laptop: 1279px;
$tablet: 1023px;
$phablet: 767px;
$mobile: 479px;

// Ширина контента
$width-container: 1320px;
