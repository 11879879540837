.section-industries {
  position: relative;
  overflow: hidden;

  &__header {
    margin-bottom: 64px;

    @include media-max($laptop) {
      margin-bottom: 48px;
    }

    @include media-max($phablet) {
      margin-bottom: 32px;
    }
  }

  &__title {
    margin-bottom: 24px;

    @include media-max($laptop) {
      margin-bottom: 16px;
    }
  }

  &__wrap {
    display: flex;
    align-items: baseline;
    justify-content: space-between;

    @include media-max($phablet) {
      display: block;
    }

    .slider-controls {
      @include media-max($phablet) {
        margin-top: 32px;
      }
    }
  }

  &--line {
    &::after {
      position: absolute;
      bottom: 0;
      left: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $c-black;
      left: calc((100vw - $width-container) / 2);

      @include media-max($laptop) {
        left: 32px;
      }

      @include media-max($tablet) {
        left: 16px;
      }

      @include media-max($phablet) {
        left: 0;
        opacity: 0.2;
      }
    }
  }
}