.header {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	padding: 30px 0;
	transition: background-color $t-base, padding $t-base;
	z-index: 5;

	@include media-max($laptop) {
		padding: 18px 0;
	}

	@include media-max($tablet) {
		padding: 16px 0;
		background-color: $c-white;
	}

	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	&.scrolled {
		background-color: $c-white;
		padding: 18px 0;

		@include media-max($phablet) {
			padding: 16px 0;
		}

		.logo {
			img {
				height: 40px;

				@include media-max($laptop) {
					height: 32px;
				}

				@include media-max($tablet) {
					height: 24px;
				}
			}
		}
	}

	&--dark {
		background-color: $c-white;
	}
}
