.product-card {
	display: flex;
	flex-direction: column;
	align-items: flex-start;

	&__prew {
		width: 100%;
		height: 314px;
		margin-bottom: 40px;
		position: relative;
		overflow: hidden;
		border-radius: 10px;

		@include media-max($laptop) {
			height: 230px;
			margin-bottom: 32px;
		}

		@include media-max($phablet) {
			height: 228px;
			margin-bottom: 24px;
		}

		@include any-hover {
			&:hover {
				&::before {
					background-color: rgba($c-accent, 0.15);
				}

				img {
					transform: scale(1.05);
				}
			}
		}

		&::before {
			position: absolute;
			content: '';
			width: 100%;
			height: 100%;
			left: 0;
			top: 0;
			background-blend-mode: hard-light;
			z-index: 1;
			transition: background-color $t-base;
		}

		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			transition: transform $t-base;
		}
	}

	&__title {
		margin-bottom: 16px;
		transition: color $t-base;

		@include media-max($laptop) {
			margin-bottom: 8px;
		}

		@include any-hover {
			&:hover {
				color: $c-accent;
			}
		}

		h4 {
			margin-bottom: 0;
		}
	}

	&__text {
		margin-bottom: 16px;
		-webkit-line-clamp: 4;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		overflow: hidden;

		@include media-max($phablet) {
			margin-bottom: 8px;
		}
	}

	&__read-more {
		margin-bottom: 40px;
		font-weight: 700;
		display: flex;
		align-items: center;
		transition: color $t-base;
		font-family: $f-orbitron, sans-serif;
		color: $c-violet;

		@include media-max($laptop) {
			margin-bottom: 24px;
		}

		@include any-hover {
			&:hover {
				color: $c-accent;

				svg {
					fill: $c-accent;
				}
			}
		}

		svg {
			width: 12px;
			height: 7.4px;
			fill: $c-violet;
			margin-left: 8px;
			transition: fill $t-base;
		}
	}

	&.active {
		.product-card__text {
			-webkit-line-clamp: initial;
		}

		.product-card__read-more {
			svg {
				transform: rotate(180deg);
			}
		}
	}
}
