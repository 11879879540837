h1,
h2,
h3,
h4 {
  font-family: $f-orbitron, sans-serif;
}

h1 {
  font-weight: 700;
  font-size: 56px;
  line-height: 64px;
  text-transform: capitalize;

  @include media-max($laptop) {
    font-size: 32px;
    line-height: 40px;
  }

  @include media-max($phablet) {
    font-size: 26px;
    line-height: 34px;
  }
}

h2 {
  font-weight: 700;
  font-size: 48px;
  line-height: 56px;
  text-transform: capitalize;
  margin-bottom: 64px;

  @include media-max($laptop) {
    font-size: 32px;
    line-height: 40px;
    margin-bottom: 32px;
  }

  @include media-max($phablet) {
    font-size: 24px;
    line-height: 32px;
    margin-bottom: 16px;
  }
}

h3 {
  font-weight: 700;
  font-size: 32px;
  line-height: 40px;
  text-transform: capitalize;
  margin-bottom: 32px;

  @include media-max($laptop) {
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 20px;
  }
}

h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 32px;
  text-transform: capitalize;
  margin-bottom: 24px;

  @include media-max($laptop) {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
  }
}

h5 {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  text-transform: uppercase;
}

h1,
h2,
h3 {
  &::after {
    content: '.';
    color: $c-violet;
  }
}

p {
  font-size: 16px;
  line-height: 28px;

  @include media-max($laptop) {
    font-size: 14px;
    line-height: 24px;
  }

  &.bold {
    font-weight: 700;
  }

  & > a {
    color: $c-violet;
    text-decoration: underline;

    @include any-hover {
      &:hover {
        text-decoration: none;
      }
    }
  }
}

a {
  color: $c-black;
}

ul {
  list-style-type: none;
}