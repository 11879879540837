body,
input,
textarea,
button {
	font-family: $f-montserrat, sans-serif;
	font-size: 16px;
	line-height: 28px;

	@include media-max($laptop) {
		font-size: 14px;
		line-height: 24px;
	}
}

body {
	background-color: $c-white-dark;
	color: $c-black;
}

.main {
	overflow: hidden;
}

.container {
	width: 100%;
	max-width: 1370px;
	padding: 0 32px;
	margin: 0 auto;

	@include media-max($tablet) {
		padding: 0 16px;
	}
}

.section {
	padding: 140px 0;

	@include media-max($laptop) {
		padding: 90px 0;
	}

	@include media-max($tablet) {
		padding: 64px 0;
	}

	@include media-max($phablet) {
		padding: 56px 0;
	}

	&--white {
		background-color: $c-white;
	}

	&--gray {
		background-color: $c-white-dark;
	}
}

.pipedriveWebForms {
	max-width: 470px !important;
}
