.slider-pagination {
  margin-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 0;
  position: initial;

  @include media-max($laptop) {
    margin-top: 36px;
  }

  @include media-max($phablet) {
    margin-top: 24px;
  }

  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: $c-black;
    position: relative;
    opacity: 1;
    transition: background-color $t-base;

    @include media-max($laptop) {
      width: 8px;
      height: 8px;
    }

    &::after {
      position: absolute;
      content: '';
      width: 28px;
      height: 28px;
      border-radius: 50%;
      border: 1px solid $c-accent;
      background-color: transparent;
      left: -6px;
      top: -6px;
      transform: scale(0.5);
      opacity: 0;
      transition: all $t-base;

      @include media-max($laptop) {
        width: 16px;
        height: 16px;
        left: -4px;
        top: -4px;
      }
    }
  }

  .swiper-pagination-bullet-active {
    background-color: $c-accent;

    &::after {
      transform: scale(1);
      opacity: 1;
    }
  }

  &.swiper-pagination-lock {
    display: none;
  }
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet,
.swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  margin: 0 11px;

  @include media-max($laptop) {
    margin: 0 6px;
  }
}