.slider-base,
.slider-base-grid {
  width: 100%;

  &__title {
    margin-bottom: 40px;

    @include media-max($laptop) {
      margin-bottom: 32px;
    }

    @include media-max($phablet) {
      margin-bottom: 20px;
    }
  }
}