.section-logos {
  padding: 48px 0;
  overflow: hidden;
  background-color: $c-violet;
  color: $c-white;

  @include media-max($laptop) {
    padding: 32px 0;
  }

  @include media-max($phablet) {
    padding: 16px 0;
  }

  &__inner {
    display: flex;
    align-items: center;

    @include media-max($tablet) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__title {
    margin-bottom: 0;

    @include media-max($tablet) {
      margin-bottom: 4px;
    }
  }

  &__item {
    width: 980px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-max($laptop) {
      width: 770px;
    }

    @include media-max($tablet) {
      width: 100%;
    }

    @include media-max($phablet) {
      display: grid;
      grid-template-columns: repeat(5, minmax(0, 1fr));
      column-gap: 12px;
    }

    img {
      @include media-max($laptop) {
        max-width: 103px;
      }

      @include media-max($phablet) {
        max-width: 100%;
      }
    }
  }

  &--type2 {
    background-color: $c-accent;
    color: $c-black;
  }
}