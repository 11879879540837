.card-base {
  height: 302px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  @include media-max($laptop) {
    height: 222px;
  }

  @include media-max($phablet) {
    height: 228px;
  }

  &::before {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: url('./../img/card-base-gradient.png');
  }

  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    background-blend-mode: normal, hard-light;
    transition: background-color $t-base;

    @include media-max($laptop) {
      padding: 24px 16px;
    }
  }

  &__icon {
    position: absolute;
    top: 32px;
    left: 32px;
    opacity: 0;
    transition: opacity $t-base;

    @include media-max($laptop) {
      width: 40px;
      height: 40px;
      top: 24px;
      left: 16px;
    }
  }

  &__text {
    position: absolute;
    left: 32px;
    right: 32px;
    top: 100%;
    transform: translateY(calc(-100% - 32px));
    transition: all $t-base;
    font-family: $f-orbitron, sans-serif;
    font-weight: 700;
    color: $c-white;

    @include media-max($laptop) {
      left: 16px;
      right: 16px;
      transform: translateY(calc(-100% - 24px));
    }
  }

  &__arrow {
    position: absolute;
    bottom: 32px;
    left: 32px;
    transform: scale(0) rotate(-45deg);
    transition: transform $t-base;

    @include media-max($laptop) {
      width: 40px;
      height: 40px;
      bottom: 24px;
      left: 16px;
    }
  }

  @include any-hover {
    &:hover {
      .card-base__content {
        background-color: $c-accent;
      }

      .card-base__icon {
        opacity: 1;
      }

      .card-base__text {
        top: 96px;
        transform: translateY(0);
        color: $c-black;

        @include media-max($laptop) {
          top: 72px;
        }
      }

      .card-base__arrow {
        transform: scale(1) rotate(0);
      }
    }
  }
}