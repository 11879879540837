.product {
  overflow: initial;

  .section-news {
    &:last-child {
      padding-bottom: 0;
    }
  }

  &__features-grid {
    padding-bottom: 140px;

    @include media-max($laptop) {
      padding-bottom: 90px;
    }

    .tabs-two {
      display: grid;
      grid-template-columns: 1fr 308px;
      align-items: start;
      column-gap: 90px;
    }
  }

  &__hardware-grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 32px;
    row-gap: 64px;

    @include media-max($laptop) {
      column-gap: 24px;
      row-gap: 40px;
    }

    @include media-max($phablet) {
      grid-template-columns: 100%;
      row-gap: 40px;
    }
  }

  &__video-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    column-gap: 64px;
    row-gap: 64px;
    align-items: center;
    padding-bottom: 64px;

    @include media-max($phablet) {
      grid-template-columns: 100%;
      row-gap: 40px;
      padding-top: 40px;
    }
    @include media-max($laptop) {
      padding-top: 56px;
    }
  }

  &__btn {
    width: 100%;
    font-size: 16px;
    font-weight: 700;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid $c-black;
    position: relative;
    text-align: left;
    padding: 15px 0;
    display: none;
    padding-right: 30px;

    @include media-max($phablet) {
      display: flex;
    }

    &::after {
      width: 18px;
      height: 18px;
      background-image: url('./../img/icons/plus.svg');
      content: '';
      position: absolute;
      top: 18px;
      right: 0;
    }

    &.active {
      border-bottom: none;

      &::after {
        background-image: url('./../img/icons/minus.svg');
      }
    }
  }
}
