.partners-calling {
  padding: 175px 0;
  background-image: url('./../img/banner-partners.jpg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  @include media-max($laptop) {
    padding: 104px 0;
  }

  @include media-max($phablet) {
    padding: 72px 0;
  }

  &::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(86deg, rgba(0, 249, 249, 0.35) 0.29%, rgba(86, 34, 191, 0.35) 75.41%), rgba(20, 20, 20, 0.40);
    background-blend-mode: normal, hard-light;
  }

  &__inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    z-index: 1;
  }

  &__title {
    margin-bottom: 40px;
    color: $c-white;

    @include media-max($laptop) {
      margin-bottom: 24px;
    }
  }
}