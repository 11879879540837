.card-partners {
  padding: 48px 40px;
  background-color: $c-white;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-height: 472px;
  position: relative;
  border-radius: 10px;
  overflow: hidden;

  @include media-max($laptop) {
    padding: 32px 24px;
    min-height: 346px;
  }

  @include media-max($phablet) {
    min-height: 337px;
    padding: 32px 16px;
  }

  @include any-hover {
    &:hover {
      .card-partners__hover {
        opacity: 1;
        pointer-events: auto;
      }
    }
  }

  &__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 48px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: opacity $t-base;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }

    .btn {
      position: relative;
      z-index: 1;
    }
  }

  &__logo {
    width: 151px;
    height: 48px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 24px;

    @include media-max($laptop) {
      width: 101px;
      height: 32px;
    }

    img {
      height: 100%;
      width: auto;
    }
  }

  &__text {
    margin-bottom: 24px;
  }

  &__link {
    margin-top: 24px;
    color: $c-violet;
    margin-top: auto;
    position: relative;

    &::after {
      position: absolute;
      bottom: 4px;
      left: 0;
      right: 0;
      content: '';
      width: 100%;
      height: 1px;
      background-color: $c-violet;
      opacity: 1;
      transition: opacity $t-base;
    }

    @include any-hover {
      &:hover {
        &::after {
          opacity: 0;
        }
      }
    }
  }

  &__link-two {
    display: flex;
    align-items: center;
    margin-top: auto;

    @include any-hover {
      &:hover {
        span {
          color: $c-violet;
        }
      }
    }

    img {
      width: 40px;
      height: 40px;
      margin-right: 16px;
    }

    span {
      font-size: 14px;
      font-weight: 500;
      color: $c-violet;
      transition: color $c-button-hover;
    }
  }
}