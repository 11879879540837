.leadership {
	&__wrap {
		display: flex;
		gap: 8px;

		@include media-max($mobile) {
			flex-direction: column;
		}
	}

	&__col {
		flex: 1;
		display: flex;
		flex-direction: column;
		gap: 8px;
	}
}
