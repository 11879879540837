.blog-category {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  a {
    font-size: 14px;
    text-transform: lowercase;
    color: $c-gray-light;
    position: relative;
    transition: color $t-base;

    @include any-hover {
      &:hover {
        color: $c-black;
      }
    }

    &:not(:last-child) {
      margin-right: 43px;

      @include media-max($phablet) {
        margin-right: 26px;
      }

      &::after {
        width: 11px;
        height: 11px;
        border-radius: 50%;
        border: 1px solid $c-violet;
        position: absolute;
        right: 0;
        top: 8px;
        content: '';
        transform: translateX(calc(100% + 16px));
        pointer-events: none;

        @include media-max($phablet) {
          top: 5px;
          transform: translateX(calc(100% + 8px));
        }
      }
    }
  }
}