.product-video {
  &__prew {
    width: 100%;
    height: 359px;
    margin-bottom: 40px;

    @include media-max($laptop) {
      height: 264px;
      margin-bottom: 24px;
    }

    @include media-max($phablet) {
      height: 162px;
    }

    iframe {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__title {}
}