.section-partners {
  &__title {
    margin-bottom: 48px;

    @include media-max($phablet) {
      margin-bottom: 32px;
    }
  }

  &__prew {
    width: 100%;
    height: 383px;
    margin-bottom: 90px;
    border-radius: 10px;
    overflow: hidden;


    @include media-max($laptop) {
      height: 280px;
      margin-bottom: 56px;
    }

    @include media-max($phablet) {
      height: 228px;
      margin-bottom: 40px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    gap: 32px;

    @include media-max($laptop) {
      gap: 24px;
    }

    @include media-max($phablet) {
      grid-template-columns: 100%;
      gap: 16px;
    }
  }

  &__load-more {
    grid-column: 3 span;
    justify-self: center;
    margin-top: 58px;

    @include media-max($laptop) {
      margin-top: 24px;
    }

    @include media-max($phablet) {
      grid-column: initial;
      margin-top: 16px;
    }

    &.hide {
      display: none;
    }
  }
}