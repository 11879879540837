.about-block {
  display: flex;
  align-items: flex-start;
  padding: 140px 0;

  @include media-max($laptop) {
    padding: 80px 0;
  }

  @include media-max($phablet) {
    flex-direction: column;
    padding: 56px 0;
  }

  &:first-child {
    padding-top: 0;
  }

  &:last-child {
    padding-bottom: 0;
  }

  &__title {
    flex: 0 0 450px;
    width: 450px;
    margin-right: 80px;

    @include media-max($laptop) {
      flex: 0 0 254px;
      width: 254px;
      margin-right: 74px;
    }

    @include media-max($phablet) {
      flex: initial;
    }
  }

  &__content {
    flex: 1 1 auto;
  }

  &--dark {
    background-color: $c-white;
    position: relative;

    &::after,
    &::before {
      position: absolute;
      top: 0;
      content: '';
      width: calc((100vw - $width-container) / 2);
      height: 100%;
      background-color: $c-white;

      @include media-max($laptop) {
        width: 32px;
      }
    }

    &::after {
      left: 0;
      transform: translateX(-100%);
    }

    &::before {
      right: 0;
      transform: translateX(100%);
    }
  }
}
