*,
*::before,
*::after {
  box-sizing: border-box;
  outline: none;

  // Убирает системное выделение интерактивных элементов
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

// Удаление крестика из input[type="search"] со всех браузеров
input[type=text]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

input[type=text]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}

input[type="search"] {
  border-radius: none;
  -webkit-appearance: none;
}

body,
input,
textarea,
button,
h1,
h2,
h3,
h4,
h5,
h6,
ul,
ol,
p {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

img {
  max-width: 100%;
  height: auto;
}

input,
select,
textarea {
  outline: none;
  border-radius: 0;
}