.card-cases {
  background-color: $c-white;
  padding: 48px 40px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  @include media-max($laptop) {
    padding: 32px 24px;
  }

  @include media-max($phablet) {
    padding: 24px 16px;
  }

  &__hover {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }

  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-bottom: 24px;

    @include media-max($laptop) {
      margin-bottom: 16px;
    }

    @include media-max($phablet) {
      flex-direction: column-reverse;
    }
  }

  &__name {
    margin-bottom: 8px;

    @include media-max($laptop) {
      margin-bottom: 4px;
    }

    @include media-max($phablet) {
      margin-bottom: 2px;
    }
  }

  &__logo {
    width: 172px;
    height: 90px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include media-max($laptop) {
      width: 122px;
      height: 64px;
    }

    @include media-max($phablet) {
      width: 76px;
      height: 40px;
      margin-bottom: 8px;
    }

    img {
      width: 100%;
      max-width: 100%;
      max-height: 100%;
      height: auto;
    }
  }

  &__content {
    p {
      margin: 20px 0;

      @include media-max($tablet) {
        margin: 16px 0;
      }
    }

    &>*:first-child {
      margin-top: 0;
    }

    &>*:last-child {
      margin-bottom: 0;
    }

    ul {
      list-style-type: none;

      li {
        padding-left: 24px;
        position: relative;

        &::before {
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 1px solid $c-violet;
          position: absolute;
          left: 0;
          top: 5px;
          content: '';
        }
      }
    }
  }
}