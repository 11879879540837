.footer {
  &__inner {
    display: flex;

    @include media-max($laptop) {
      flex-direction: column-reverse;
    }
  }

  .logo {
    @include media-max($laptop) {
      justify-self: start;
      margin-bottom: 14px;
    }
  }

  &__info {
    width: 230px;
    flex: 0 0 230px;
    margin-right: 80px;

    @include media-max($laptop) {
      flex: initial;
      display: grid;
      grid-template-columns: 2fr 1fr 1fr;
      grid-template-rows: auto auto;
      column-gap: 100px;
      width: 100%;
      margin-right: 0;
    }

    @include media-max($phablet) {
      display: block;
    }
  }

  &__contacts {
    margin: 30px 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @include media-max($laptop) {
      grid-row-start: 2;
      margin: 0;
    }

    @include media-max($phablet) {
      margin-bottom: 24px;
    }

    a {
      transition: color $t-base;

      @include any-hover {
        &:hover {
          color: $c-violet;
        }
      }

      &:not(:last-child) {
        margin-bottom: 8px;

        @include media-max($laptop) {
          margin-bottom: 0;
        }
      }
    }
  }

  &__social {
    display: flex;
    align-items: center;
    margin-top: 32px;

    @include media-max($laptop) {
      grid-row: 2 span;
      margin-top: 0;
      align-self: flex-end;
    }

    li {
      &:not(:last-child) {
        margin-right: 24px;
      }
    }

    a {
      display: block;
      width: 24px;
      height: 24px;
      color: $c-black;
      transition: color $t-base;

      @include any-hover {
        &:hover {
          color: $c-violet;
        }
      }

      svg {
        width: 100%;
        height: 100%;
        fill: currentColor;
      }
    }
  }

  &__wrap {
    flex: 1 1 auto;

    @include media-max($laptop) {
      margin-bottom: 48px;
    }

    @include media-max($phablet) {
      display: none;
    }
  }

  &__row {
    display: grid;
    grid-template-columns: 3fr 1.6fr 1fr;
    column-gap: 64px;

    @include media-max($laptop) {
      grid-template-columns: 2fr 1fr 1fr;
      column-gap: 100px;
    }

    &:not(:last-child) {
      margin-bottom: 32px;

      @include media-max($laptop) {
        margin-bottom: 24px;
      }
    }
  }

  &__nav {
    a {
      transition: color $t-base;

      @include any-hover {
        &:hover {
          color: $c-violet;
        }
      }
    }
  }

  &__nav-title {
    margin-bottom: 16px;

    @include media-max($laptop) {
      margin-bottom: 8px;
    }
  }
}